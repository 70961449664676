:root {
  --tw-slate-0: #f8fafc;
  --tw-slate-1: #f1f5f9;
  --tw-slate-2: #e2e8f0;
  --tw-slate-3: #cbd5e1;
  --tw-slate-4: #94a3b8;
  --tw-slate-5: #64748b;
  --tw-slate-6: #475569;
  --tw-slate-7: #334155;
  --tw-slate-8: #1e293b;
  --tw-slate-9: #0f172a;
  --tw-gray-0: #f9fafb;
  --tw-gray-1: #f3f4f6;
  --tw-gray-2: #e5e7eb;
  --tw-gray-3: #d1d5db;
  --tw-gray-4: #9ca3af;
  --tw-gray-5: #6b7280;
  --tw-gray-6: #4b5563;
  --tw-gray-7: #374151;
  --tw-gray-8: #1f2937;
  --tw-gray-9: #111827;
  --tw-zinc-0: #fafafa;
  --tw-zinc-1: #f4f4f5;
  --tw-zinc-2: #e4e4e7;
  --tw-zinc-3: #d4d4d8;
  --tw-zinc-4: #a1a1aa;
  --tw-zinc-5: #71717a;
  --tw-zinc-6: #52525b;
  --tw-zinc-7: #3f3f46;
  --tw-zinc-8: #27272a;
  --tw-zinc-9: #18181b;
  --tw-neutral-0: #fafafa;
  --tw-neutral-1: #f5f5f5;
  --tw-neutral-2: #e5e5e5;
  --tw-neutral-3: #d4d4d4;
  --tw-neutral-4: #a3a3a3;
  --tw-neutral-5: #737373;
  --tw-neutral-6: #525252;
  --tw-neutral-7: #404040;
  --tw-neutral-8: #262626;
  --tw-neutral-9: #171717;
  --tw-stone-0: #fafaf9;
  --tw-stone-1: #f5f5f4;
  --tw-stone-2: #e7e5e4;
  --tw-stone-3: #d6d3d1;
  --tw-stone-4: #a8a29e;
  --tw-stone-5: #78716c;
  --tw-stone-6: #57534e;
  --tw-stone-7: #44403c;
  --tw-stone-8: #292524;
  --tw-stone-9: #1c1917;
  --tw-red-0: #fef2f2;
  --tw-red-1: #fee2e2;
  --tw-red-2: #fecaca;
  --tw-red-3: #fca5a5;
  --tw-red-4: #f87171;
  --tw-red-5: #ef4444;
  --tw-red-6: #dc2626;
  --tw-red-7: #b91c1c;
  --tw-red-8: #991b1b;
  --tw-red-9: #7f1d1d;
  --tw-orange-0: #fff7ed;
  --tw-orange-1: #ffedd5;
  --tw-orange-2: #fed7aa;
  --tw-orange-3: #fdba74;
  --tw-orange-4: #fb923c;
  --tw-orange-5: #f97316;
  --tw-orange-6: #ea580c;
  --tw-orange-7: #c2410c;
  --tw-orange-8: #9a3412;
  --tw-orange-9: #7c2d12;
  --tw-amber-0: #fffbeb;
  --tw-amber-1: #fef3c7;
  --tw-amber-2: #fde68a;
  --tw-amber-3: #fcd34d;
  --tw-amber-4: #fbbf24;
  --tw-amber-5: #f59e0b;
  --tw-amber-6: #d97706;
  --tw-amber-7: #b45309;
  --tw-amber-8: #92400e;
  --tw-amber-9: #78350f;
  --tw-yellow-0: #fefce8;
  --tw-yellow-1: #fef9c3;
  --tw-yellow-2: #fef08a;
  --tw-yellow-3: #fde047;
  --tw-yellow-4: #facc15;
  --tw-yellow-5: #eab308;
  --tw-yellow-6: #ca8a04;
  --tw-yellow-7: #a16207;
  --tw-yellow-8: #854d0e;
  --tw-yellow-9: #713f12;
  --tw-lime-0: #f7fee7;
  --tw-lime-1: #ecfccb;
  --tw-lime-2: #d9f99d;
  --tw-lime-3: #bef264;
  --tw-lime-4: #a3e635;
  --tw-lime-5: #84cc16;
  --tw-lime-6: #65a30d;
  --tw-lime-7: #4d7c0f;
  --tw-lime-8: #3f6212;
  --tw-lime-9: #365314;
  --tw-green-0: #f0fdf4;
  --tw-green-1: #dcfce7;
  --tw-green-2: #bbf7d0;
  --tw-green-3: #86efac;
  --tw-green-4: #4ade80;
  --tw-green-5: #22c55e;
  --tw-green-6: #16a34a;
  --tw-green-7: #15803d;
  --tw-green-8: #166534;
  --tw-green-9: #14532d;
  --tw-emerald-0: #ecfdf5;
  --tw-emerald-1: #d1fae5;
  --tw-emerald-2: #a7f3d0;
  --tw-emerald-3: #6ee7b7;
  --tw-emerald-4: #34d399;
  --tw-emerald-5: #10b981;
  --tw-emerald-6: #059669;
  --tw-emerald-7: #047857;
  --tw-emerald-8: #065f46;
  --tw-emerald-9: #064e3b;
  --tw-teal-0: #f0fdfa;
  --tw-teal-1: #ccfbf1;
  --tw-teal-2: #99f6e4;
  --tw-teal-3: #5eead4;
  --tw-teal-4: #2dd4bf;
  --tw-teal-5: #14b8a6;
  --tw-teal-6: #0d9488;
  --tw-teal-7: #0f766e;
  --tw-teal-8: #115e59;
  --tw-teal-9: #134e4a;
  --tw-cyan-0: #ecfeff;
  --tw-cyan-1: #cffafe;
  --tw-cyan-2: #a5f3fc;
  --tw-cyan-3: #67e8f9;
  --tw-cyan-4: #22d3ee;
  --tw-cyan-5: #06b6d4;
  --tw-cyan-6: #0891b2;
  --tw-cyan-7: #0e7490;
  --tw-cyan-8: #155e75;
  --tw-cyan-9: #164e63;
  --tw-sky-0: #f0f9ff;
  --tw-sky-1: #e0f2fe;
  --tw-sky-2: #bae6fd;
  --tw-sky-3: #7dd3fc;
  --tw-sky-4: #38bdf8;
  --tw-sky-5: #0ea5e9;
  --tw-sky-6: #0284c7;
  --tw-sky-7: #0369a1;
  --tw-sky-8: #075985;
  --tw-sky-9: #0c4a6e;
  --tw-blue-0: #eff6ff;
  --tw-blue-1: #dbeafe;
  --tw-blue-2: #bfdbfe;
  --tw-blue-3: #93c5fd;
  --tw-blue-4: #60a5fa;
  --tw-blue-5: #3b82f6;
  --tw-blue-6: #2563eb;
  --tw-blue-7: #1d4ed8;
  --tw-blue-8: #1e40af;
  --tw-blue-9: #1e3a8a;
  --tw-indigo-0: #eef2ff;
  --tw-indigo-1: #e0e7ff;
  --tw-indigo-2: #c7d2fe;
  --tw-indigo-3: #a5b4fc;
  --tw-indigo-4: #818cf8;
  --tw-indigo-5: #6366f1;
  --tw-indigo-6: #4f46e5;
  --tw-indigo-7: #4338ca;
  --tw-indigo-8: #3730a3;
  --tw-indigo-9: #312e81;
  --tw-violet-0: #f5f3ff;
  --tw-violet-1: #ede9fe;
  --tw-violet-2: #ddd6fe;
  --tw-violet-3: #c4b5fd;
  --tw-violet-4: #a78bfa;
  --tw-violet-5: #8b5cf6;
  --tw-violet-6: #7c3aed;
  --tw-violet-7: #6d28d9;
  --tw-violet-8: #5b21b6;
  --tw-violet-9: #4c1d95;
  --tw-purple-0: #faf5ff;
  --tw-purple-1: #f3e8ff;
  --tw-purple-2: #e9d5ff;
  --tw-purple-3: #d8b4fe;
  --tw-purple-4: #c084fc;
  --tw-purple-5: #a855f7;
  --tw-purple-6: #9333ea;
  --tw-purple-7: #7e22ce;
  --tw-purple-8: #6b21a8;
  --tw-purple-9: #581c87;
  --tw-fuchsia-0: #fdf4ff;
  --tw-fuchsia-1: #fae8ff;
  --tw-fuchsia-2: #f5d0fe;
  --tw-fuchsia-3: #f0abfc;
  --tw-fuchsia-4: #e879f9;
  --tw-fuchsia-5: #d946ef;
  --tw-fuchsia-6: #c026d3;
  --tw-fuchsia-7: #a21caf;
  --tw-fuchsia-8: #86198f;
  --tw-fuchsia-9: #701a75;
  --tw-pink-0: #fdf2f8;
  --tw-pink-1: #fce7f3;
  --tw-pink-2: #fbcfe8;
  --tw-pink-3: #f9a8d4;
  --tw-pink-4: #f472b6;
  --tw-pink-5: #ec4899;
  --tw-pink-6: #db2777;
  --tw-pink-7: #be185d;
  --tw-pink-8: #9d174d;
  --tw-pink-9: #831843;
  --tw-rose-0: #fff1f2;
  --tw-rose-1: #ffe4e6;
  --tw-rose-2: #fecdd3;
  --tw-rose-3: #fda4af;
  --tw-rose-4: #fb7185;
  --tw-rose-5: #f43f5e;
  --tw-rose-6: #e11d48;
  --tw-rose-7: #be123c;
  --tw-rose-8: #9f1239;
  --tw-rose-9: #881337;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: var(--tw-gray-8);
  font-family: "Assistant", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Navbar Styling */
.navbar {
  background-color: white;
  /* box-shadow: 0 0 5px 0.5px var(--tw-slate-2); */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 5%;
  width: 100%;
  position: relative;
}

.title-name {
  font-weight: 700;
  font-size: 20px;
}

.links-div {
  display: flex;
  align-items: center;
}

.navbar-navlink {
  color: black;
  font-size: 17px;
  font-weight: 600;
  text-decoration: none;
}

.navbar-navlink:nth-of-type(1) {
  margin-right: 5vw;
}

.links-div .active {
  color: var(--tw-cyan-6);
  text-decoration: underline;
  text-underline-offset: 2px;
}

/* Global Styles */

.about-main h1 {
  font-size: 1.5rem;
  margin: 20px 0;
}

.about-main p {
  font-size: 18px;
  margin-bottom: 1rem;
}

.section-div {
  padding: 50px 5% 100px;
  background-color: white;
}

/* About - Intro Section */

.about-intro-section {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
}

.intro-title-text {
  width: 100%;
}

.intro-title-text h1 {
  color: var(--tw-sky-6);
  font-size: 2rem;
}

.span-bold {
  font-weight: 600;
}

.about-intro-image {
  background-image: url("../images/portrait1.jpg");
  background-position: 150px -15px;
  background-size: 190px;
  height: 110px;
  width: 110px;
  border-radius: 50%;
  border: 5px solid var(--tw-gray-2);
  margin-right: 1rem;
}

.about-socials-section {
  color: var(--tw-gray-6);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem 0 0;
}

.about-socials-section a {
  color: var(--tw-gray-6);
}

.about-socials-section .socials-icon {
  cursor: pointer;
  margin-right: 2rem;
}

.about-socials-section .socials-icon:hover {
  color: var(--tw-gray-9);
}

.about-socials-section .fa-envelope {
  margin-right: 16px;
}

.email-copy-div {
  position: relative;
}

.copy-notice {
  color: var(--tw-gray-5);
  position: absolute;
  top: -30px;
  left: 60px;
  visibility: hidden;
}

.email-copy-div p {
  font-size: 0.9rem;
}

.about-intro-email {
  font-size: 1rem !important;
  font-weight: 600;
  width: max-content;
}

/* About - Skills Section */

.section-div:nth-of-type(2) {
  background-color: var(--tw-sky-1);
  height: fit-content;
}

.skills-table {
  display: flex;
  flex-direction: row;
}

.skills-btn-div {
  height: 32px;
  width: min-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 2rem;
}

.skill-btn {
  cursor: pointer;
  background-color: white;
  border: 1px solid var(--tw-blue-5);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  margin: 0 24px 4px 0;
  padding: 10px 14px;
  width: 120px;
}

.skill-btn:hover {
  background-color: var(--tw-blue-1);
}

.active-skill-btn {
  color: white;
  background-color: var(--tw-blue-5);
}

.active-skill-btn:hover {
  color: white;
  background-color: var(--tw-blue-5);
}

.skills-info-div .fa-terminal {
  color: var(--tw-cyan-6);
  margin-right: 1rem;
}

.skills-info-div {
  margin: 1rem 0 2rem;
}

.skills-info-div p {
  font-size: 16px;
}

.skills-info-text {
  display: flex;
}

.skills-info-text .fa-terminal {
  margin-right: 0.5rem;
}

.skill-point {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.dropdown-title-btn {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.dropdown-title-btn button {
  color: var(--tw-cyan-6);
  cursor: pointer;
  background-color: var(--tw-sky-1);
  border: none;
}

.dropdown-title-btn h4 {
  font-size: 16px;
  font-weight: 600;
}

.dropdown-div hr {
  margin: 14px 0;
  border: none;
  border-bottom: 1px solid var(--tw-gray-3);
}

/* About - Myself Section */

.about-myself-section a {
  color: var(--tw-gray-8);
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-top: 2rem;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.about-myself-section p {
  font-size: 18px;
}

/* Portfolio Styles */

.folio-main {
  background-color: var(--tw-slate-0);
  padding: 50px 0 100px;
  width: 100%;
}

.folio-main h1 {
  font-size: 2rem;
  margin: 20px 0 3rem 5%;
}

.folio-projects-div {
  display: grid;
  gap: 3rem;
  grid-template-columns: minmax(250px, 500px);
  grid-auto-rows: 1fr;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
}

.project-div {
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 20px 0.5px var(--tw-gray-3);
  border-radius: 15px;
}

.project-image-div {
  width: 100%;
  margin: 0 auto;
  margin-right: 2rem;
}

.project-image {
  width: 100%;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 5px 0.5px var(--tw-gray-3);
}

.project-details {
  padding: 1rem;
}

.links-icons-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.project-links-div {
  width: 100%;
  margin-bottom: 14px;
}

.links-icons-div p {
  color: var(--tw-gray-5);
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  width: 100%;
}

.project-link {
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-right: 6px;
  padding: 4px 10px;
  text-decoration: none;
  white-space: nowrap;
  transition: 0.2s all ease;
}

.project-link.site {
  border: 1px solid var(--tw-green-5);
  color: var(--tw-green-6);
}

.project-link.site:hover {
  background-color: var(--tw-green-5);
  color: white;
}

.project-link.code {
  border: 1px solid var(--tw-purple-5);
  color: var(--tw-purple-6);
}

.project-link.code:hover {
  background-color: var(--tw-purple-5);
  color: white;
}

.project-title {
  margin: 12px 0 2px;
}

.project-type-date {
  display: flex;
  flex-direction: column;
}

.project-type {
  color: var(--tw-cyan-6);
}

.project-date {
  font-size: 14px;
  margin-top: 6px;
}

.project-description {
  margin: 6px 0 10px;
}

/* Footer Contact Section */
.footer-div {
  background-color: var(--tw-zinc-1);
  padding: 40px 5% 70px;
  display: flex;
  flex-direction: column;
}

.contact-details {
  display: flex;
  flex-direction: column;
}

.contact-details h1 {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.contact-details p {
  margin-bottom: 8px;
}

.linkedin-github-div a {
  color: var(--tw-gray-8);
  text-decoration: none;
}

.linkedin-github-div p:hover {
  cursor: pointer;
}

.phone-div,
.email-div {
  position: relative;
  width: 300px;
}

.phone-div .icon,
.email-div .icon {
  color: var(--tw-gray-5);
  position: absolute;
  visibility: hidden;
}

.phone-div .icon {
  left: 150px;
  bottom: -7px;
}

.email-div .icon {
  left: 210px;
  bottom: -7px;
}

.footer-icon {
  margin-right: 10px;
}

.footer-copyright-div {
  margin-top: 40px;
}

/* Responsive Breakpoints */

@media (min-width: 600px) and (max-width: 949px) {
  .links-icons-div {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .project-links-div {
    width: 50%;
  }

  .links-icons-div p {
    text-align: right;
    width: 50%;
  }

  .project-type-date {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .project-description {
    margin: 8px 0 10px;
  }
}

@media (min-width: 600px) {
  .section-div:nth-of-type(2) {
    height: 480px;
  }
}

@media (min-width: 700px) {
  .folio-main {
    padding: 50px 40px 100px;
    width: 100%;
  }

  .navbar-navlink:nth-of-type(1) {
    margin-right: 2rem;
  }
}

@media (min-width: 800px) {
  .about-intro-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .intro-title-text {
    width: 70%;
  }

  .about-intro-image {
    background-position: 203px -18px;
    background-size: 250px;
    height: 155px;
    width: 155px;
    border: 5px solid var(--tw-gray-2);
    margin-right: 1rem;
  }

  .section-div:nth-of-type(2) {
    height: fit-content;
  }

  .skills-table {
    flex-direction: column;
  }

  .skills-btn-div {
    flex-direction: row;
    width: 100%;
  }

  .skill-btn {
    margin: 0 24px 0 0;
    padding: 12px 14px;
    width: max-content;
  }

  .skills-info-div .fa-terminal {
    margin-right: 1.5rem;
  }
}

@media (min-width: 900px) {
  .navbar {
    padding: 30px 90px;
  }

  .section-div {
    padding: 50px 90px 100px;
  }

  .about-intro-email:hover {
    cursor: pointer;
    color: var(--tw-cyan-6);
  }

  .copy-notice {
    visibility: visible;
  }

  .footer-div {
    padding: 40px 90px 70px;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .contact-details {
    flex-direction: row;
    align-items: flex-end;
  }

  .contact-details div:nth-of-type(1) {
    margin-right: 3rem;
  }

  .contact-details p:hover {
    cursor: pointer;
    color: var(--tw-cyan-6);
  }

  .phone-div .icon,
  .email-div .icon {
    visibility: visible;
  }
}

@media (min-width: 950px) {
  .folio-projects-div {
    gap: 3rem;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
  }
}

@media (min-width: 1100px) {
  .links-icons-div {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .project-links-div {
    width: 50%;
  }

  .links-icons-div p {
    text-align: right;
    width: 50%;
  }

  .project-type-date {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  .project-description {
    margin: 8px 0 10px;
  }
}

@media (min-width: 1250px) {
  .about-intro-image {
    margin-right: 10%;
  }

  .about-intro-email {
    margin-right: 15%;
    width: 310px;
  }

  .project-links-div {
    width: 35%;
  }

  .links-icons-div p {
    width: 65%;
  }
}